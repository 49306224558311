import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { createProductSchema } from '../utils/schema'

function SEO({
  description, metaDesc, lang, meta, keywords, title, product,
  bodyClass, schema, image, opengraphType
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || metaDesc || data.site.siteMetadata.description

        const productSchema = createProductSchema(data.site.siteMetadata.title, data.site.siteMetadata.siteUrl, product)

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: product ? 'product' : opengraphType,
              },
              {
                property: `og:image`,
                content: data.site.siteMetadata.siteUrl + image,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: data.site.siteMetadata.siteUrl + image,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <script type="application/ld+json">{ `${productSchema || schema?.raw}` }</script>
            <body className={bodyClass} />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  bodyClass: ``,
  product: null,
  schema: null,
  opengraphType: `website`,
}

SEO.propTypes = {
  description: PropTypes.string,
  metaDesc: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
