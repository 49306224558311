import he from 'he'

const createProductSchema = (brand, siteUrl, product) => {

  if (!product) return null

  let variants = product.variants?.map((el, i) => {
    return `{
      "@type" : "Offer",
      "availability" : "http://schema.org/InStock",
      "price" : "${ el.price }",
      "priceCurrency" : "AUD",
      "url" : "${ siteUrl }/products/${ product.handle }",
      "itemOffered" :
      {
          "@type" : "Product",
          "image": "${ el.image?.originalSrc }"
          "name" : "${ el.title }",
          "url" : "${ siteUrl }/products/${ product.handle }"
      }
    }`
  }).join()

  return `
  {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": "${ he.encode(product.title) }",
    "url": "${ siteUrl }/products/${ product.handle }",
    "image": [
      "${ product.images[0].originalSrc }"
    ],
    "description": "${ he.encode(product.description) }",
    "brand": {
      "@type": "Thing",
      "name": "${product.description}"
    },
    "offers": [
      ${ variants }
    ]
  }
  `
}

export { createProductSchema }
